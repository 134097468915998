import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ruRU } from '@material-ui/core/locale';
import './styles.scss';

export default function TableM(props) {
    const theme = createMuiTheme({}, ruRU);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('agreement_no');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(15)

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
      
    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
          const order = comparator(a[0], b[0]);
          if (order !== 0) return order;
          return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
      };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    return (
        <ThemeProvider theme={theme}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'date'}
                                    direction={orderBy === 'date' ? order : 'asc'}
                                    onClick={createSortHandler('date')}
                                >
                                    Звітний період
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'price_monthly_clear'}
                                    direction={orderBy === 'price_monthly_clear' ? order : 'asc'}
                                    onClick={createSortHandler('price_monthly_clear')}
                                >
                                    Фактична оплата, грн
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'price_monthly_expected'}
                                    direction={orderBy === 'price_monthly_expected' ? order : 'asc'}
                                    onClick={createSortHandler('price_monthly_expected')}
                                >
                                    Очікувана місячна плата, грн
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'difference'}
                                    direction={orderBy === 'difference' ? order : 'asc'}
                                    onClick={createSortHandler('difference')}
                                >
                                    Очікувані щомісячні втрати, грн
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                    {stableSort(props.agreements, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                            return (
                                <TableRow key={row.agreement_no}>
                                    <TableCell>{row.date}</TableCell>
                                    <TableCell>{row.price_monthly_clear && row.price_monthly_clear.toFixed(2)}</TableCell>
                                    <TableCell>{row.price_monthly_expected && row.price_monthly_expected.toFixed(2)}</TableCell>
                                    <TableCell><span style={{color: `${row.difference >= 0 ? '#00AD31' : '#FF0000'}`}}>{row.difference && row.difference.toFixed(2)}</span></TableCell>
                                </TableRow>
                            )
                        })
                    }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={props.agreements.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
            />
        </ThemeProvider>
    )
}