import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import axios from "axios";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import Question from "../images/question.svg";
import { Link } from "gatsby";
import TableM from "../components/Table/Table";
import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  LineSeries,
} from "react-vis";

export default class Agreements extends React.Component {
  constructor(props) {
    super(props);

    const universal_caption =
      'Натисніть "Поскаржитись правоохоронцям", аби дізнатись більше про цей ризик';

    this.state = {
      risks: [
        {
          label: "Є потенційна недоплата",
          caption: universal_caption, // "Є потенційна недоплата",
          value: "is_expected_2_plus_times_gr_than_payment",
        },
        {
          label: "Некоректний код ЄДРПОУ орендаря",
          caption: universal_caption, // "Некоректний код ЄДРПОУ орендаря",
          value: "is_tennant_code_bug",
        },
        {
          label: "Некоректний код ЄДРПОУ балансоутримувача",
          caption: universal_caption, // "Некоректний код ЄДРПОУ балансоутримувача",
          value: "is_owner_code_bug",
        },
        {
          label: "Ризик заниженої оціночної вартості",
          caption: universal_caption, // "Ризик заниженої оціночної вартості",
          value: "is_less_avg_price_m2",
        },
        {
          label: "Є уточнення до ціни",
          caption: universal_caption, // "Є уточнення до ціни",
          value: "is_price_footnotes",
        },
        {
          label: "Пільгова ставка для бізнесу",
          caption: universal_caption, // "Пільгова ставка для бізнесу",
          value: "is_tennant_business_with_cheap_rent",
        },
        {
          label: "Оренда бізнесом під потреби ГО",
          caption: universal_caption, // "Оренда бізнесом під потреби ГО",
          value: "is_tennant_ngo_business",
        },
        {
          label: "Тривале подовження у 2019-2020 рр.",
          caption: universal_caption, // "Тривале подовження у 2019-2020 рр.",
          value: "is_big_longations_after_2019",
        },
        {
          label: "Багато ГО за однією адресою",
          caption: universal_caption, // "Багато ГО за однією адресою",
          value: "is_more_than_one_ngo_agreement_per_object",
        },
      ],
      types: [
        {
          value: "GE",
          label: "Державний орган",
        },
        {
          value: "PE",
          label: "Фізична особа або ФОП",
        },
        {
          value: "LE",
          label: "Юридична особа",
        },
        {
          value: "Abroad",
          label: "Закордонний орендар",
        },
        {
          value: "Communal",
          label: "Комунальне підприємство",
        },
        {
          value: "Self-emp",
          label: "Нотаріуси та адвокати",
        },
      ],
      agreements: null,
      currentDate: null,
      id: "",
      table: null,
      chartData: null,
      useCanvas: false,
      width: 700,
    };

    this.wWrapper = React.createRef();
  }

  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (!urlParams.get("id")) window.location.href = "404";
    this.setState({
      id: urlParams.get("id"),
    });

    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();

    this.setState({
      currentDate: yyyy + mm + dd,
    });

    axios
      .get(
        `${process.env.API_ENDPOINT}/estate/agreements/${urlParams.get("id")}`
      )
      .then((response) => {
        this.setState({
          agreements: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get(
        `${process.env.API_ENDPOINT}/estate/agreements/${urlParams.get(
          "id"
        )}/finance`
      )
      .then((response) => {
        this.setState({
          table: response.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const agreement = this.state.agreements && this.state.agreements[0];
    let risksStatus = false;

    this.state.agreements &&
      this.state.risks.forEach((item) => {
        if (agreement[item.value] && agreement[item.value] == 1)
          risksStatus = true;
      });

    const greenData =
      this.state.table &&
      this.state.table.map((item) => ({
        x: item.date.split("-")[1] + "." + item.date.split("-")[0],
        y: item.price_monthly_clear,
      }));
    const blueData =
      this.state.table &&
      this.state.table.map((item) => ({
        x: item.date.split("-")[1] + "." + item.date.split("-")[0],
        y: item.price_monthly_expected,
      }));
    const maxValue_clear =
      this.state.table &&
      Math.max.apply(
        Math,
        this.state.table.map((item) => item.price_monthly_clear)
      );
    const maxValue_expected =
      this.state.table &&
      Math.max.apply(
        Math,
        this.state.table.map((item) => item.price_monthly_expected)
      );
    const maxValue =
      this.state.table && Math.max(maxValue_clear, maxValue_expected);
    const chartLeftMargin = Math.max(
      (Math.ceil(maxValue) / 10).toString().length * 10 - 10,
      40
    );

    return (
      <React.Fragment>
        {this.state.agreements && (
          <Layout>
            <SEO
              title={`Договір №${agreement.agreement_no} від ${agreement.agreement_date_start}`}
            />
            <div className="view-agreement">
              <div className="view-container">
                <Breadcrumbs
                  items={[
                    {
                      title: "Головна",
                      url: "/",
                    },
                    {
                      title: "Об`єкт",
                      url: `/object/?id=${agreement.object_id}`,
                    },
                    {
                      title: "Договір",
                    },
                  ]}
                />
                <h1 className="view-title view-title--h2">
                  Договір №{agreement.agreement_no} від{" "}
                  {agreement.agreement_date_start}
                  <br />
                  <span>діє до {agreement.agreement_date_end}</span>
                </h1>

                <div className="view-agreement__header">
                  <div className="view-agreement__data">
                    <div className="view-agreement__data__list">
                      <div className="view-agreement__data__item">
                        <span>Адреса:</span>
                        <b>
                          {agreement.object_address && agreement.object_address}
                        </b>
                      </div>
                      <div className="view-agreement__data__item">
                        <span>Статус:</span>
                        <b>
                          {agreement.agreement_date_end.replace(/[\-%]/g, "") <
                          this.state.currentDate
                            ? "Недіючий"
                            : "Діючий"}
                        </b>
                      </div>
                      <div className="view-agreement__data__item">
                        <span>Площа:</span>
                        <b>{agreement.size_clear && agreement.size_clear} м²</b>
                      </div>
                      {/* <div className="view-agreement__data__item">
                                                <span>Сума місячної оплати:</span>
                                                <b>{agreement.price_monthly_expected && agreement.price_monthly_expected.toFixed(2)} грн.</b>
                                            </div> */}
                      <div className="view-agreement__data__item">
                        <span>Сума фактичної місячної плати</span>
                        <b>
                          {agreement.price_monthly_clear &&
                            agreement.price_monthly_clear.toFixed(2)}{" "}
                          грн.
                        </b>
                      </div>
                      <div className="view-agreement__data__item">
                        <span>Сума очікуваної орендної плати</span>
                        <b>
                          {agreement.price_monthly_expected &&
                            (agreement.price_monthly_expected).toFixed(
                              2
                            )}{" "}
                          грн.
                        </b>
                      </div>
                      <div className="view-agreement__data__item">
                        <span>Вартість майна</span>
                        <b>
                          {agreement.price_clear &&
                            agreement.price_clear.toFixed(2)}{" "}
                          грн.
                        </b>
                      </div>
                      <div className="view-agreement__data__item">
                        <span>Орендна ставка</span>
                        <b>
                          {agreement.rent_law_share_num &&
                            agreement.rent_law_share_num}
                          %
                        </b>
                      </div>
                      <div className="view-agreement__data__item">
                        <span>Орендар:</span>
                        <b>{agreement.tennant_name}</b>
                      </div>
                      <div className="view-agreement__data__item">
                        <span>Тип майна</span>
                        <b>{agreement.property_type}</b>
                      </div>
                      <div className="view-agreement__data__item">
                        <span>Тип орендаря</span>
                        <b>
                          {agreement.entity_type &&
                            this.state.types.find(
                              (type) => type.value == agreement.entity_type
                            ).label}
                        </b>
                      </div>
                      <div className="view-agreement__data__item">
                        <span>Орендодавець</span>
                        <b>{agreement.owner_name}</b>
                      </div>
                      <div className="view-agreement__data__item">
                        <span>Цільове призначення</span>
                        <b>{agreement.rent_law_share_text}</b>
                      </div>
                    </div>
                  </div>
                  <div className="view-agreement__graph" ref={this.wWrapper}>
                    <div className="view-agreement__graph__title">
                      <b>Орендна плата</b>
                    </div>
                    <div className="view-agreement__graph__item">
                      {console.log("maxValue", maxValue)}
                      {console.log("chartLeftMargin", chartLeftMargin)}
                      {this.state.table && (
                        <FlexibleXYPlot
                          xType="ordinal"
                          height={300}
                          xDistance={100}
                          yDomain={[0, maxValue]}
                          margin={{ left: chartLeftMargin }}
                        >
                          <VerticalGridLines />
                          <HorizontalGridLines />
                          <XAxis />
                          <YAxis />
                          <LineSeries data={greenData} />
                          <LineSeries data={blueData} />
                        </FlexibleXYPlot>
                      )}
                    </div>
                    <div className="view-agreement__graph__caption">
                      <div>
                        <pre style={{ background: "rgb(18, 147, 154)" }}></pre>
                        Фактична оплата
                      </div>
                      <div>
                        <pre style={{ background: "rgb(121, 199, 227)" }}></pre>
                        Очікувана оплата
                      </div>
                    </div>
                  </div>
                  {risksStatus && (
                    <div className="view-agreement__risks">
                      <div className="view-call__risks">
                        <div className="view-call__risks__title">Ризики</div>
                        <div className="view-call__risks__list">
                          {this.state.risks.map(
                            (item) =>
                              agreement[item.value] == 1 && (
                                <div className="view-call__risks__item">
                                  {item.label}
                                  <div className="view-call__risks__qt">
                                    <Question />
                                    <span>{item.caption}</span>
                                  </div>
                                </div>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {!risksStatus && <div></div>}
                  <div className="view-agreement__header__actions">
                    <Link
                      to={`/call?id=${this.state.id}`}
                      className="button button--primary"
                    >
                      Поскаржитись правоохоронцям
                    </Link>
                    <Link
                      to={`/get-agreement?id=${this.state.id}`}
                      className="button button--link"
                    >
                      Отримати договір
                    </Link>
                  </div>
                </div>
              </div>

              {this.state.table && (
                <div className="view-agreement__table">
                  <div className="view-container">
                    <TableM agreements={this.state.table} />
                  </div>
                </div>
              )}
            </div>
          </Layout>
        )}
      </React.Fragment>
    );
  }
}
