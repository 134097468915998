import React from 'react';
import Arrow from '../../images/arrow.svg';
import { Link } from 'gatsby';
import './styles.scss';

export default function Breadcrumbs(props) {

    return (
        <div className="view-breadcrumbs">
            <Arrow onClick={() => window.history.back()}/>

            {props.items.map((item, key) => (
                item.url ? 
                    <Link key={key} to={item.url} className="view-breadcrumbs__item">
                        { item.title }
                    </Link>
                :
                <span key={key} className="view-breadcrumbs__item">
                    { item.title }
                </span>
            ))}
        </div>
    );
}